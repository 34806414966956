<template lang="pug">
settings-panel
  template(#header) Логин

  .settings-panel__card-inputs
    validation-row(
      watch-validation
      not-empty
      type-email
      v-model="legalEntity.login"
      label="Логин"
      placeholder="Введите логин"
      @error="errorChecker('form_email')"
      @correct="correctChecker('form_email')"
    )
  warning-message
    | Логин используется для входа в систему. На него отправляется <b>служебная рассылка</b> - обновление быстрой ссылки / смена пароля / информационная рассылка.
    |
    template(v-if="enableSmsNotifications || enableEmailNotifications")
      | Для рассылки по шаблонам, заявкам и контрактам используется <b>email для уведомлений</b>, указанный в разделе
      |
      accessed-link.settings-panel__link(:name="Tabs.Settings.Notifications") УВЕДОМЛЕНИЯ.

  template(v-if="message || showInfoMessage || isLinkError" v-slot:comments)
    span.error-message(v-if="isLinkError") {{ isLinkError}}
    template(v-if="message")
      span Возникла ошибка при сохранении данных:
      span.error-message {{ message }}
    .info-message(v-if="showInfoMessage")
      span Вы успешно обновили свой email. На новый адрес будет направляться служебная рассылка (смена пароля/быстрой ссылки), а также данный адрес будет использован как логин для входа в систему.
    .info-message(v-if="showInfoMessage && (enableSmsNotifications || enableEmailNotifications)")
      | Пожалуйста, проверьте, что почта для уведомлений актуальна.
      |
      accessed-link.settings-panel__link(:name="Tabs.Settings.Notifications") Перейти

  template(#actions)
    ui-button(:loader="loader" @click="validateForm") Сохранить
    ui-button(type="tertiary" :loader="isUpdatingLink" @click="refreshLink") Обновить быструю ссылку для входа

pop-confirm(v-model:show="showPopConfirm" @event="popConfirmEvent")
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, watch } from 'vue'
import { useForm } from "@/use/other/useForm";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useHasChanges } from "@/use/other/useHasChanges";
import { onBeforeRouteLeave } from "vue-router";
import { useQuickLink } from "@/use/security/useQuickLink";
import { useEmailModifier } from "@/use/security/useEmailModifier";

import UiButton from "@/components/ui/button/UiButton.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import { Tabs } from "@/router/tabs";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import WarningMessage from "~/components/ui/alert/WarningMessage.vue";

export default defineComponent({
  name: "EmailPanel",
  components: {
    WarningMessage,
    UiButton,
    AccessedLink,
    SettingsPanel,
    ValidationRow,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
  },
  setup() {

    const {
      me,
      enableSmsNotifications,
      enableEmailNotifications,
    } = useUserAccess();

    const {
      loader: isUpdatingLink,
      message: isLinkError,
      updateLink,
    } = useQuickLink();

    const {
      loader,
      message,
      updateEmail,
    } = useEmailModifier(false);

    const showInfoMessage = ref(false);

    function getLoginEntity() {
      return {
        login: me.value.login,
      }
    }

    const legalEntity = ref(getLoginEntity());
    watch(me, () => {
      legalEntity.value = getLoginEntity()
      updateCopy()
    })

    function saveChanges() {
      showInfoMessage.value = false;

      const s = legalEntity.value.login !== me.value.login

      updateEmail(me.value?.id, legalEntity.value.login)
        .then(() => {
          showInfoMessage.value = s
          updateCopy()
        })

    }

    function refreshLink() {
      updateLink(me.value?.id)
    }

    const { validateForm, errorChecker, correctChecker } = useForm(saveChanges);

    const {
      showPopConfirm,
      popConfirmEvent,
      checkChanges,
      updateCopy,
    } = useHasChanges(legalEntity);

    onBeforeRouteLeave((to, from, next) => {
      if (to.name !== Tabs.Landing) checkChanges(next)
      else next()
    })

    return {
      Tabs,
      me,
      isLinkError,
      loader,
      legalEntity,
      showPopConfirm,
      enableSmsNotifications,
      enableEmailNotifications,
      showInfoMessage,
      message,
      isUpdatingLink,
      refreshLink,
      validateForm,
      errorChecker,
      correctChecker,
      popConfirmEvent,
    };
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';
@import '@/assets/styles/mixin/links';

.settings-panel__link {
  @include link-mixin;
  //margin-left: 4px;
}

.error-message {
  color: var(--main-red-color);
}

.info-message {
  color: var(--default-blue-color);
}

.settings-panel__paragraph {
  @include label-13-16;
  display: inline-flex;
  color: var(--main-color-black);
}

.settings-panel__card-inputs {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 760px) {
  .settings-panel__card-inputs {
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }

  .snare {
    display: none;
  }
}
</style>
